import {
    IS_LOADING_CHANGED,
    DATA_LOADED,
    SELECTED_CHANGED,
    SELECT_DESELECT_ALL,
    CALCULATABLE_FIELD_DATA_CHANGED,
    CALCULATABLE_FIELD_IS_LOADING_CHANGED,
    CALCULATABLE_TOTALS_IS_LOADING_CHANGED,
    CALCULATABLE_TOTALS_DATA_CHANGED,
    CALCULATABLE_TOTALS_CLEAR
} from "../actions/resultsActions";

const makeEmptyCalculatableTotals = () => ({
    totalChecked: { isLoading: false, data: null },
    nok: { isLoading: false, data: null },
    totalHours: { isLoading: false, data: null },
    totalReworked: { isLoading: false, data: null }
});

const makeInitialState = _ => ({
    isLoading: true,
    rows: [],
    totalRows: 0,
    enabledColumns: [],
    calculatableTotals: makeEmptyCalculatableTotals()
});

export default function results(state = makeInitialState(), action) {
    switch (action.type) {
        case IS_LOADING_CHANGED:
            return {
                ...state,
                isLoading: action.payload
            };
        case DATA_LOADED:
            return {
                ...state,
                isLoading: false,
                totalRows: action.totalRows,
                enabledColumns: action.enabledColumns,
                rows: action.payload
            };
        case SELECTED_CHANGED:
            return {
                ...state,
                rows: state.rows.map(r => (r._id === action.rowId ? { ...r, selected: action.payload } : r))
            };
        case SELECT_DESELECT_ALL:
            return {
                ...state,
                rows: state.rows.map(r => (r.selected !== action.payload ? { ...r, selected: action.payload } : r))
            };
        case CALCULATABLE_FIELD_DATA_CHANGED:
            return {
                ...state,
                rows: state.rows.map(r =>
                    r._id === action.rowId
                        ? { ...r, [action.fieldKey]: { ...r[action.fieldKey], isLoading: false, data: action.payload } }
                        : r
                )
            };
        case CALCULATABLE_FIELD_IS_LOADING_CHANGED:
            return {
                ...state,
                rows: state.rows.map(r =>
                    r._id === action.rowId
                        ? { ...r, [action.fieldKey]: { ...r[action.fieldKey], isLoading: action.payload } }
                        : r
                )
            };
        case CALCULATABLE_TOTALS_IS_LOADING_CHANGED:
            return {
                ...state,
                calculatableTotals: {
                    ...state.calculatableTotals,
                    [action.fieldKey]: {
                        ...state.calculatableTotals[action.fieldKey],
                        isLoading: action.payload
                    }
                }
            };
        case CALCULATABLE_TOTALS_DATA_CHANGED:
            return {
                ...state,
                calculatableTotals: {
                    ...state.calculatableTotals,
                    [action.fieldKey]: {
                        ...state.calculatableTotals[action.fieldKey],
                        data: action.payload,
                        isLoading: false
                    }
                }
            };
        case CALCULATABLE_TOTALS_CLEAR:
            return {
                ...state,
                calculatableTotals: makeEmptyCalculatableTotals()
            };
        default:
            return state;
    }
}
