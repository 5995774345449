import BaloonCell from "../components/common/AutoTable/cells/BaloonCell";
import DateTextCell from "../components/common/AutoTable/cells/DateTextCell";
import LinkCell from "../components/common/AutoTable/cells/LinkCell";
import MatSortableTableFooter from "../components/common/AutoTable/MatSortableTableFooter";
import MatSortableTableHeader from "../components/common/AutoTable/MatSortableTableHeader";
import { encodeParams } from "../utils";
import CalculatableTotalFooterCell from "./components/CalculatableTotalFooterCell";
import FinishOrderButtonCell from "./components/FinishOrderButtonCell";
import FinishOrderButtonHeaderCell from "./components/FinishOrderButtonHeaderCell";
import NewReportButtonCell from "./components/NewReportButtonCell";
import NokCell from "./components/NokCell";
import OrdersListTableRow from "./components/OrdersListTableRow";
import SelectAllCheckboxCell from "./components/SelectAllCheckboxCell";
import SelectItemCheckboxCell from "./components/SelectItemCheckboxCell";
import TotalCheckedCell from "./components/TotalCheckedCell";
import TotalReworkedCell from "./components/TotalReworkedCell";
import TotalHoursCell from "./components/TotalHoursCell";

export default {
    header: MatSortableTableHeader,
    footer: MatSortableTableFooter,
    rowsSelector: state => state.ordersList.results.rows,
    useMatTable: true,
    showNoResultsText: true,
    customRow: OrdersListTableRow,
    stickyHeader: true,
    processColumns: (columns, state) => {
        const columnsMap = {};
        for (const col of state.ordersList.results.enabledColumns) {
            columnsMap[col] = true;
        }
        return columns.filter(col => !col.customizerSwitch || columnsMap[col.key]);
    },
    columns: [
        {
            title: "Select",
            valueSelector: () => null,
            customTopHeader: SelectAllCheckboxCell,
            cell: SelectItemCheckboxCell
        },
        {
            key: "finishOrderButton",
            title: "Finish",
            valueSelector: (r, i) => null,
            customTopHeader: FinishOrderButtonHeaderCell,
            cell: FinishOrderButtonCell,
            sortable: false,
            customizerSwitch: true,
            enabledByDefault: true,
            backendKey: "finished"
        },
        {
            key: "newReportButton",
            title: "Add new report",
            valueSelector: (r, i) => null,
            cell: NewReportButtonCell,
            sortable: false,
            customizerSwitch: true,
            enabledByDefault: true,
            backendKey: null
        },
        {
            key: "country",
            title: "Country",
            valueSelector: (r, i) => r.country,
            cell: LinkCell,
            to: row => encodeParams`/dashboard/orders/${row._id}`,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "orderNumber",
            title: "Order number",
            valueSelector: (r, i) => r.orderNumber,
            cell: LinkCell,
            to: row => encodeParams`/dashboard/orders/${row._id}`,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "officialOrderName",
            title: "Official order name",
            valueSelector: (r, i) => r.officialOrderName,
            cell: BaloonCell,
            to: row => encodeParams`/dashboard/orders/${row._id}`,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "unofficialOrderName",
            title: "Unofficial order name",
            valueSelector: (r, i) => r.unofficialOrderName,
            cell: LinkCell,
            to: row => encodeParams`/dashboard/orders/${row._id}`,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: false
        },
        {
            key: "partNumbers",
            title: "Part numbers",
            valueSelector: (r, i) => r.partNumbers,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "partNames",
            title: "Part names",
            valueSelector: (r, i) => r.partNames,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true,
            enabledByDefault: true
        },
        {
            key: "managed",
            title: "Managed",
            valueSelector: (r, i) => r.managed,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "startDate",
            title: "Start date",
            valueSelector: (r, i) => r.startDate,
            cell: DateTextCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "closedAt",
            title: "Closed at",
            valueSelector: (r, i) => r.closedAt,
            cell: DateTextCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "customer",
            title: "Customer",
            valueSelector: (r, i) => r.customer,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "customerCompanyUnofficialName",
            title: "Customer unofficial name",
            valueSelector: (r, i) => r.customerCompanyUnofficialName,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "customerContactPerson",
            title: "Customer contact person",
            valueSelector: (r, i) => r.customerContactPerson,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "location",
            title: "Location",
            valueSelector: (r, i) => r.location,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "locationContactPerson",
            title: "Location contact person",
            valueSelector: (r, i) => r.locationContactPerson,
            cell: BaloonCell,
            sortable: true,
            customizerSwitch: true
        },
        {
            key: "totalChecked",
            title: "Total checked",
            valueSelector: (r, i) => r.totalChecked,
            cell: TotalCheckedCell,
            customFooterCell: CalculatableTotalFooterCell,
            calculatableFormat: data => `Ext: ${data.external} Int: ${data.internal}`,
            sortable: false,
            customizerSwitch: true,
            backendKey: null
        },
        {
            key: "totalReworked",
            title: "Total reworked",
            valueSelector: (r, i) => r.totalReworked,
            cell: TotalReworkedCell,
            customFooterCell: CalculatableTotalFooterCell,
            calculatableFormat: data => `Ext: ${data.external} Int: ${data.internal}`,
            sortable: false,
            customizerSwitch: true,
            backendKey: null
        },
        {
            key: "nok",
            title: "Total NOK",
            valueSelector: (r, i) => r.nok,
            cell: NokCell,
            customFooterCell: CalculatableTotalFooterCell,
            calculatableFormat: data => `Ext NoK: ${data.external} Int NoK: ${data.internal}`,
            sortable: false,
            customizerSwitch: true,
            backendKey: null
        },
        {
            key: "totalHours",
            title: "Total hours",
            valueSelector: (r, i) => r.totalHours,
            cell: TotalHoursCell,
            customFooterCell: CalculatableTotalFooterCell,
            calculatableFormat: data => `Ext h: ${data.external} Int h: ${data.internal}`,
            sortable: false,
            customizerSwitch: true,
            backendKey: null
        }
    ]
};
